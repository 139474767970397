@use 'helpers/variables';

.app-link,
.app-link .btn {
  font-size: 14px;
  color: variables.$theme-color-primary-accent;
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: 0;

  &:hover {
    color: variables.$theme-color-primary-accent;
    text-decoration: underline;
  }

  &:active {
    color: variables.$theme-color-primary-accent;
  }

  &:disabled {
    color: variables.$theme-color-secondary-text;
    text-decoration: none;
    cursor: not-allowed;
  }

  &:focus {
    box-shadow: none;
  }
}
