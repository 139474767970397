@use 'helpers/variables';

$floater-bottom: 0;
$floater-bottom-xl: 0;
$floater-bottom-up: variables.$wizard-actions-bottom-safe-space;
$floater-bottom-up-md: variables.$wizard-actions-bottom-safe-space-md;
$floater-bottom-up-xl: variables.$wizard-actions-bottom-safe-space-xl;
$floater-bottom-up-batch-menu: variables.$bottom-menu-height;

// Floating Freshchat Widget Integration

#fc_frame.fc_frame {
  $floater-fc-bottom-offset: 8px;

  z-index: variables.$z-index-freshworks !important;
  right: #{variables.$floater-outer-spacing - 5px} !important;
  bottom: #{$floater-bottom + $floater-fc-bottom-offset} !important;

  @media (min-width: variables.$screen-xl-min) {
    bottom: #{$floater-bottom-xl + $floater-fc-bottom-offset} !important;
  }

  // offset for batch menu's sticky bottom:
  body.batch-menu-visible & {
    bottom: #{$floater-bottom-up-batch-menu + $floater-fc-bottom-offset} !important;
  }

  // offset for wizard's sticky bottom:
  body.wizard-actions-visible & {
    bottom: #{$floater-bottom-up + $floater-fc-bottom-offset} !important;

    @media (min-width: variables.$screen-md-min) {
      bottom: #{$floater-bottom-up-md + $floater-fc-bottom-offset} !important;
    }

    @media (min-width: variables.$screen-xl-min) {
      bottom: #{$floater-bottom-up-xl + $floater-fc-bottom-offset} !important;
    }
  }

  body.hide-freshworks-widgets & {
    display: none !important;
  }
}

// Floating Knowledge Base Widget Integration

#freshworks-container {
  $floater-kb-bottom-offset: 73px;
  $floater-kb-outer-top-spacing: variables.$floater-outer-spacing + 49px;
  $floater-kb-popup-additional-bottom: 60px;

  #launcher-frame,
  #freshworks-frame-wrapper {
    z-index: variables.$z-index-freshworks !important;
    left: auto !important;
  }

  #launcher-frame {
    width: 88px !important;
    min-width: 0 !important;
    max-width: unset !important;
    right: variables.$floater-outer-spacing !important;
    bottom: #{$floater-bottom + $floater-kb-bottom-offset} !important;

    @media (min-width: variables.$screen-xl-min) {
      bottom: #{$floater-bottom-xl + $floater-kb-bottom-offset} !important;
    }
  }

  #freshworks-frame-wrapper {
    width: auto !important;
    height: auto !important;
    max-height: none !important;
    top: $floater-kb-outer-top-spacing !important;
    right: variables.$floater-outer-spacing !important;
    bottom: variables.$floater-outer-spacing !important;
    z-index: variables.$z-index-freshworks + 1 !important;

    @media (min-width: variables.$screen-md-min) {
      width: 384px !important;
      height: calc(
        100% - #{$floater-bottom + $floater-kb-popup-additional-bottom +
          $floater-kb-outer-top-spacing}
      ) !important;
      top: auto !important;
      bottom: #{$floater-bottom + $floater-kb-popup-additional-bottom} !important;
    }

    @media (min-width: variables.$screen-lg-min) {
      height: calc(
        100% - #{$floater-bottom + $floater-kb-popup-additional-bottom +
          $floater-kb-outer-top-spacing}
      ) !important;
    }

    @media (min-width: variables.$screen-xl-min) {
      height: calc(
        100% - #{$floater-bottom-xl + $floater-kb-popup-additional-bottom +
          $floater-kb-outer-top-spacing}
      ) !important;
      bottom: #{$floater-bottom-xl + $floater-kb-popup-additional-bottom} !important;
    }
  }

  // offset for batch menu's sticky bottom:
  body.batch-menu-visible & {
    #launcher-frame {
      bottom: #{$floater-bottom-up-batch-menu + $floater-kb-bottom-offset} !important;
    }

    #freshworks-frame-wrapper {
      @media (min-width: variables.$screen-md-min) {
        height: auto !important;
        top: $floater-kb-outer-top-spacing !important;
        bottom: variables.$floater-outer-spacing !important;
      }

      @media (min-width: variables.$screen-lg-min) {
        height: calc(
          100% - #{$floater-bottom-up-batch-menu +
            $floater-kb-popup-additional-bottom + $floater-kb-outer-top-spacing}
        ) !important;
        top: auto !important;
        bottom: #{$floater-bottom-up-batch-menu +
          $floater-kb-popup-additional-bottom} !important;
      }
    }
  }

  // offset for wizard's sticky bottom:
  body.wizard-actions-visible & {
    #launcher-frame {
      bottom: #{$floater-bottom-up + $floater-kb-bottom-offset} !important;

      @media (min-width: variables.$screen-md-min) {
        bottom: #{$floater-bottom-up-md + $floater-kb-bottom-offset} !important;
      }

      @media (min-width: variables.$screen-xl-min) {
        bottom: #{$floater-bottom-up-xl + $floater-kb-bottom-offset} !important;
      }
    }

    #freshworks-frame-wrapper {
      @media (min-width: variables.$screen-md-min) {
        height: auto !important;
        top: $floater-kb-outer-top-spacing !important;
        bottom: variables.$floater-outer-spacing !important;
      }

      @media (min-width: variables.$screen-lg-min) {
        height: calc(
          100% - #{$floater-bottom-up-md + $floater-kb-popup-additional-bottom +
            $floater-kb-outer-top-spacing}
        ) !important;
        top: auto !important;
        bottom: #{$floater-bottom-up-md + $floater-kb-popup-additional-bottom} !important;
      }

      @media (min-width: variables.$screen-xl-min) {
        height: calc(
          100% - #{$floater-bottom-up-xl + $floater-kb-popup-additional-bottom +
            $floater-kb-outer-top-spacing}
        ) !important;
        bottom: #{$floater-bottom-up-xl + $floater-kb-popup-additional-bottom} !important;
      }
    }
  }

  body.hide-freshworks-widgets & {
    #launcher-frame,
    #freshworks-frame-wrapper {
      display: none !important;
    }
  }
}
