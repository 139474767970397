@use 'helpers/variables';
@use 'bootstrap/scss/bootstrap' as bs;

$border-width: bs.$accordion-border-width;
$border-color: bs.$accordion-border-color;

.accordion-button {
  &:focus {
    box-shadow: inset 0 calc(-1 * $border-width) 0 $border-color;

    &.collapsed {
      box-shadow: none;
    }
  }

  &:not(.collapsed) {
    background-color: variables.$white;
  }
}
