@use 'variables';
@use 'placeholders';

// Disclaimer mixins usage:
// It's ok to have mixins without property inputs. So we don't need to convert them to placeholders,
// as that causes issues, because placeholders will then not override parent styles.

// Why We Use Mixins Instead of Placeholders:
// 1. Direct Inclusion: Mixins are included directly into the CSS of the selector where they're used,
//    ensuring styles apply as if written directly within the selector.
// 2. Avoids Specificity Issues: Mixins don't introduce specificity challenges that can occur with @extend
//    making styling more predictable.
// 3. Dynamic Styles: Mixins support arguments, allowing for more dynamic and customizable style definitions compared to placeholders.
// 4. Clear Overrides: Using mixins makes it easier to override base styles with modifier classes,
//    as the included styles are treated with the same specificity as the selector they're included in.

@mixin clearfix() {
  &:before,
  &:after {
    content: ' '; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

@mixin narrow-login-container() {
  .main-container {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  .content-container {
    margin: auto;
    width: 100%;
    max-width: variables.$login-box-width;
  }
}

@mixin wizard-base-margin {
  margin: 36px 25% 40px;

  @media (max-width: variables.$screen-md-max) {
    margin: 30px 20% 40px;
  }
}

@mixin colorized-svg-icon($color) {
  .cls-1,
  .cls-2,
  .cls-3 {
    stroke: $color !important;
    fill: none !important;
    stroke-linecap: round !important;
    stroke-linejoin: round !important;
    stroke-miterlimit: 10 !important;
  }
  &.text {
    tspan {
      color: $color !important;
    }
  }
  &.path {
    path {
      stroke: $color !important;
    }
  }
  &.rect {
    rect {
      stroke: $color !important;
    }
  }
  &.circle {
    circle {
      stroke: $color !important;
    }
  }
  &.ellipse {
    ellipse {
      stroke: $color !important;
    }
  }
  &.polyline {
    polyline {
      stroke: $color !important;
    }
  }
  &.polygon {
    polygon {
      stroke: $color !important;
    }
  }
  &.polygon-fill {
    polygon {
      fill: $color !important;
    }
  }
  &.ellipse-fill {
    ellipse {
      fill: $color !important;
    }
  }
  &.circle-fill {
    circle {
      fill: $color !important;
    }
  }
  &.path-fill {
    path {
      fill: $color !important;
    }
  }
  &.rect-fill {
    rect {
      fill: $color !important;
    }
  }
  &.line {
    line {
      stroke: $color !important;
    }
  }
}

@mixin colorized-path-fill-circle-stroke-svg-icon($circleStroke) {
  &.circle-fill {
    circle {
      stroke: $circleStroke !important;
    }
  }
  &.path-fill {
    path {
      fill: variables.$white !important;
    }
  }
}

@mixin colorized-path-stroke-svg-icon() {
  &.path-fill {
    path {
      stroke: variables.$white !important;
    }
  }
}

@mixin selector-with-viewport-cases($selector) {
  #{$selector} {
    @content;

    &\@xs {
      @media (max-width: variables.$screen-xs-max) {
        @content;
      }
    }

    &\@sm {
      @media (min-width: variables.$screen-sm-min) and (max-width: variables.$screen-sm-max) {
        @content;
      }
    }

    &\@sm-min {
      @media (min-width: variables.$screen-sm-min) {
        @content;
      }
    }

    &\@sm-max {
      @media (max-width: variables.$screen-sm-max) {
        @content;
      }
    }

    &\@md {
      @media (min-width: variables.$screen-md-min) and (max-width: variables.$screen-md-max) {
        @content;
      }
    }

    &\@md-min {
      @media (min-width: variables.$screen-md-min) {
        @content;
      }
    }

    &\@md-max {
      @media (max-width: variables.$screen-md-max) {
        @content;
      }
    }

    &\@lg {
      @media (min-width: variables.$screen-lg-min) and (max-width: variables.$screen-lg-max) {
        @content;
      }
    }

    &\@lg-min {
      @media (min-width: variables.$screen-lg-min) {
        @content;
      }
    }

    &\@lg-max {
      @media (max-width: variables.$screen-lg-max) {
        @content;
      }
    }

    &\@xl {
      @media (min-width: variables.$screen-xl-min) {
        @content;
      }
    }
  }
}

@mixin floating-header {
  .floating-header {
    @media (max-width: variables.$screen-md-max) {
      width: 100%;
      top: 0;
      margin-bottom: 0;
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &--nav {
        padding-bottom: 0;
      }
    }

    &--glass {
      @extend %glass-effect;
    }

    &--scrolled {
      position: fixed !important;
    }
  }
}

@mixin dropdown-toggle-frameless {
  border: 0;
  border-radius: 0;
  box-shadow: none;

  &::after {
    display: none;
  }
}
