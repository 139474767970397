/* You can add global styles to this file, and also import other style files */

.search-btn {
  position: absolute;
  top: 11px;
  right: 8px;

  .btn {
    padding: 0 !important;
  }
}
