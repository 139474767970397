@use 'sass:map';
@use 'helpers/variables';

$cardPadding: (
  none: 0,
  small: 12px,
  default: variables.$base-padding,
  large: 20px,
  extra: 24px
);

$cardBackgrounds: (
  default: variables.$white,
  active: variables.$color-normal-blue,
  info: variables.$color-light-blue,
  success: variables.$color-light-green,
  warning: variables.$color-light-yellow,
  error: variables.$color-light-red,
  help: variables.$color-light-purple
);

$cardBorderColors: (
  default: #ced2d5,
  active: #3486ef,
  info: #039be5,
  success: #5fbf89,
  warning: #f7c976,
  error: #ed5d57,
  help: #884dff
);

@function generate-hover-gradient($color) {
  @return linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.04) 0%,
    rgba(0, 0, 0, 0.04) 100%,
    #{$color}
  );
}

@each $variant, $color in $cardBackgrounds {
  /*
  Loops through each variant and creates a class for it
  with cooresponding background color and hover styles
  then maps the border color to the variant
  */

  .card--type-#{$variant} {
    background-color: $color;
    transition: background-color 0.3s ease-in-out;

    &.card--clickable {
      // Apply hover styles only if .card--hover is present
      &:hover {
        @if $variant != default {
          background: generate-hover-gradient($color), $color;
        }
      }
    }
  }

  .card--border-style-#{$variant} {
    border-color: map.get($cardBorderColors, $variant);
  }
}

// Loops through each padding size and creates a class for each
@each $size, $padding in $cardPadding {
  .card--padding-#{$size} {
    padding: $padding;
  }
}
