@use 'helpers/mixins' as m;

.u {
  @include m.selector-with-viewport-cases('&-overflow-hidden') {
    overflow: hidden !important;
  }

  @include m.selector-with-viewport-cases('&-white-space-pre') {
    white-space: pre !important;
  }

  @include m.selector-with-viewport-cases('&-hide') {
    display: none !important;
  }

  @include m.selector-with-viewport-cases('&-width-auto') {
    width: auto !important;
  }
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.d-initial {
  display: initial !important;
}

.p-static {
  position: static;
}

.c-pointer {
  cursor: pointer;
}

.text-ellipsis {
  @extend %text-ellipsis;
}

// add to flex-child with .text-ellipsis inside
// => https://css-tricks.com/flexbox-truncated-text/
.min-width-0 {
  min-width: 0;
}

.flex-1 {
  flex: 1
}

.ripple-effect {
  position: absolute;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  transform: scale(0);
  animation: ripple-animation 0.3s linear;
  pointer-events: none;
}

@keyframes ripple-animation {
  to {
    transform: scale(2);
    opacity: 0;
  }
}

.chevron-non-rotated {
  transform: rotate(0deg);
  transition: transform 0.375s ease;
}

.chevron-rotated {
  transform: rotate(-180deg); // minus for right-sided rotation
  transition: transform 0.375s ease;
}
