@use 'sass:color';
@use 'helpers/variables';

.mat-mdc-slider {
  width: calc(100% - 16px) !important;

  .mdc-slider__track--active {
    background-color: #ebf2ff !important;
    border-radius: 2px !important;
    opacity: 1 !important;
  }

  .mdc-slider__thumb-knob {
    border-color: variables.$theme-color-primary-accent !important;
    background-color: variables.$theme-color-primary-accent !important;
    border-radius: 50% !important;
  }

  .mdc-slider__track--active_fill {
    border-color: variables.$theme-color-primary-accent !important;
  }

  .mdc-slider__thumb:not(.mdc-slider__thumb--with-indicator) {
    .mdc-slider__value-indicator {
      display: none !important;
    }
  }

  .mdc-slider__thumb--with-indicator {
    .mdc-slider__value-indicator-container {
      .mdc-slider__value-indicator {
        background-color: variables.$theme-color-primary-accent !important;
        color: #ebf2ff !important;
        padding: 0.1rem 0.3rem;
        height: auto;
        display: flex;
        justify-content: center;

        &:before {
          display: none !important;
          transition: unset !important;
        }

        &:after {
          display: none !important;
          transition: unset !important;
        }

        .mdc-slider__value-indicator-text {
          font-size: 13px;
        }
      }
    }
  }

  .slider__label-container {
    position: absolute;
    bottom: -8px;
    display: flex;
    width: calc(100% + 8px);
    justify-content: space-between;
    left: -8px;
  }
}
