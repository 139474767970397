@use 'sass:color';

// Layout

$base-size: 8px;

// Font Styles

$font-family-name-default: 'Source Sans Pro';
$font-family-default: $font-family-name-default, sans-serif;

$font-size-tiny: 11px;
$font-size-small: 13px;
$font-size-normal: 15px;
$font-size-100: 17px;
$font-size-large: 20px;

$font-weight-normal: 400;
$font-weight-semibold: 600;

// Theme Colors

$theme-color-app-background: var(--theme-color-app-background);
$theme-color-card-background: var(--theme-color-card-background);
$theme-color-menu-background: #050b13;

$theme-color-primary-accent: var(--theme-color-primary-accent);
$theme-color-primary-accent-bw-contrast: var(
  --theme-color-primary-accent-bw-contrast
);
$theme-color-primary-accent-light: var(--theme-color-primary-accent-light);
$theme-color-primary-accent-light-bw-contrast: var(
  --theme-color-primary-accent-light-bw-contrast
);
$theme-color-primary-accent-dark: var(--theme-color-primary-accent-dark);
$theme-color-primary-accent-dark-bw-contrast: var(
  --theme-color-primary-accent-dark-bw-contrast
);

$theme-color-secondary-accent: var(--theme-color-secondary-accent);
$theme-color-secondary-accent-bw-contrast: var(
  --theme-color-secondary-accent-bw-contrast
);
$theme-color-secondary-accent-light: var(--theme-color-secondary-accent-light);
$theme-color-secondary-accent-light-bw-contrast: var(
  --theme-color-secondary-accent-light-bw-contrast
);
$theme-color-secondary-accent-lighter: var(
  --theme-color-secondary-accent-lighter
);
$theme-color-secondary-accent-lighter-bw-contrast: var(
  --theme-color-secondary-accent-lighter-bw-contrast
);
$theme-color-secondary-accent-dark: var(--theme-color-secondary-accent-dark);
$theme-color-secondary-accent-dark-bw-contrast: var(
  --theme-color-secondary-accent-dark-bw-contrast
);

$theme-color-primary-text: var(--theme-color-primary-text);
$theme-color-secondary-text: var(--theme-color-secondary-text);
$theme-color-button-text: var(--theme-color-button-text);

$theme-color-state-100-ultra-light: var(
  --theme-color-state-100-ultra-light
); // color.scale($theme-color-state-100, $lightness: 90%);
$theme-color-state-100-light: var(
  --theme-color-state-100-light
); // color.scale($theme-color-state-100, $lightness: 70%);
$theme-color-state-100: var(--theme-color-state-100);
$theme-color-state-100-dark: var(
  --theme-color-state-100-dark
); // color.scale($theme-color-state-100, $blackness: 20%);

$theme-color-state-200-ultra-light: var(
  --theme-color-state-200-ultra-light
); // color.scale($theme-color-state-200, $lightness: 90%);
$theme-color-state-200-light: var(
  --theme-color-state-200-light
); // color.scale($theme-color-state-200, $lightness: 70%);
$theme-color-state-200: var(--theme-color-state-200);
$theme-color-state-200-dark: var(
  --theme-color-state-200-dark
); // color.scale($theme-color-state-200, $blackness: 20%);

$theme-color-state-300: var(--theme-color-state-300);

$theme-color-state-500-ultra-light: var(
  --theme-color-state-500-ultra-light
); // color.scale($theme-color-state-500, $lightness: 90%);
$theme-color-state-500-light: var(
  --theme-color-state-500-light
); // color.scale($theme-color-state-500, $lightness: 70%);
$theme-color-state-500: var(--theme-color-state-500);
$theme-color-state-500-dark: var(
  --theme-color-state-500-dark
); // color.scale($theme-color-state-500, $blackness: 20%);

$theme-color-state-700: var(--theme-color-state-700);

$theme-color-state-900-ultra-light: var(
  --theme-color-state-900-ultra-light
); // color.scale($theme-color-state-900, $lightness: 90%);
$theme-color-state-900-light: var(
  --theme-color-state-900-light
); // color.scale($theme-color-state-900, $lightness: 70%);
$theme-color-state-900: var(--theme-color-state-900);
$theme-color-state-900-dark: var(
  --theme-color-state-900-dark
); // color.scale($theme-color-state-900, $blackness: 20%);

$theme-color-neutral: var(--theme-color-neutral);
$theme-color-neutral-alpha-50: var(--theme-color-neutral-alpha-50);

$theme-color-menu-bar-background: var(--theme-color-menu-bar-background);
$theme-color-menu-bar-item-active: var(--theme-color-menu-bar-item-active);
$theme-color-menu-bar-item-inactive: var(--theme-color-menu-bar-item-inactive);
// Depths

$z-index-freshworks: 2147483000;
$z-index-batch-menu: $z-index-freshworks + 100;
$z-index-side-sheet: $z-index-batch-menu + 100;
$z-index-navigation: $z-index-side-sheet + 100;
$z-index-header: $z-index-navigation + 100;
$z-index-ad-block-info: $z-index-navigation + 100;
$z-index-modal: $z-index-ad-block-info + 100;
$z-index-tooltip: $z-index-modal + 100;
$z-index-toast: $z-index-modal + 100;

// UI Colors

$color-skeleton-base: $theme-color-neutral-alpha-50;
$color-skeleton-shine: $theme-color-app-background;

$color-card-border: $theme-color-neutral;
$color-card-border-hover: $theme-color-secondary-text;
$color-card-border-active: $theme-color-primary-accent;
$color-card-border-state-100: $theme-color-state-100;
$color-card-border-alert: #d81b60;

$color-badge-border: #fff;
$color-badge-text-dark: #000;
$color-badge-text-light: #fff;

$color-badge-contact-guest: $theme-color-state-500;
$color-badge-contact-exclusive: #808080;
$color-badge-contact-tenant: #afb83b;
$color-badge-contact-offline: $theme-color-state-900;
$color-badge-contact-anonymous: #884dff;
$color-badge-contact-manual: #4fe8e8;
$color-badge-contact-member: $theme-color-state-100-dark;
$color-badge-contact-project: #288b9e;
$color-badge-contact-dynamic: #e7ba14;
$color-badge-contact-self: #ff006c;
$color-badge-contact-merge-case: #f6bf25;

$color-badge-digital-contract-nect: #6accbc;
$color-badge-digital-contract-bank: #eb96eb;
$color-badge-digital-contract-identity: #158fad;

$color-normal-blue: #ebf2ff;
$color-light-blue: #e6f5fc;
$color-light-green: #e7f5ed;
$color-light-purple: #ede4ff;
$color-light-red: #fce7e6;
$color-light-yellow: #fef9e9;
$color-mustard-hover: #c5991e;

$color-data-privacy-zero: $theme-color-primary-accent-light;
$color-data-privacy-one: $theme-color-primary-accent;
$color-data-privacy-two: $theme-color-primary-accent-dark;
$color-data-privacy-three: $theme-color-secondary-accent;

$color-schufa-label-background: $theme-color-state-500;

$color-divider-line: $theme-color-neutral;

// Legacy color vars – translated
// TODO: replace old vars with new vars or name old vars "legacy"
//  (currently this is only a mapping/translation from new to old):

$background-primary: $theme-color-app-background;
$background-light: $theme-color-card-background;
$background-main-navigation: $theme-color-menu-background;

$secondary: $theme-color-primary-accent;
$secondary-light: $theme-color-primary-accent-light;
$secondary-dark: $theme-color-primary-accent-dark;

$main: $theme-color-secondary-accent;
$main-dark: $theme-color-secondary-accent-dark;

$black: $theme-color-primary-text;
$grey: $theme-color-secondary-text;

$button-text: $theme-color-button-text;

$blue: #1088d3;
$green: $theme-color-state-100;
$positive: $theme-color-state-100;
$green-dark: $theme-color-state-100-dark;
$yellow: $theme-color-state-500;
$warning: $theme-color-state-700;
$orange: $theme-color-state-700;
$red: $theme-color-state-900;
$negative: $theme-color-state-900;
$background-negative: $theme-color-state-900-ultra-light;
$red-dark: $theme-color-state-900-dark;
$banner-red: $theme-color-state-900-dark;

$white: #fff;
$smoke: $theme-color-app-background;
$platinum: $theme-color-neutral;
$platinum-light: $theme-color-neutral-alpha-50;

$intense-box-shadow-color: $theme-color-neutral;

$data-table-row-hover-color: #f5f5f5;

// Custom media query variables

$screen-xxs-max: 319px; // *
$screen-xs-min: $screen-xxs-max + 1; // *
$screen-mac-13-max-width: 1440px;
// * = pseudo breakpoints based on the supported screen width of min. 320 px

$screen-xs-max: 575px;
$screen-sm-min: $screen-xs-max + 1;
$screen-sm-max: 767px;
$screen-md-min: $screen-sm-max + 1;
$screen-md-max: 991px;
$screen-lg-min: $screen-md-max + 1;
$screen-lg-max: 1199px;
$screen-xl-min: $screen-lg-max + 1;

// Layout variables

$header-height: 48px;
$cookies-header-height: 75px;
$navigation-full-width-screen-max: $screen-sm-max;

$content-max-width: 1140px;
$border-radius-big: 12px;
$border-radius-small: 6px;

$border-color-input-field-active: $theme-color-secondary-text;

$box-shadow-default: 0 2px 1px -1px rgb(0 0 0 / 20%),
  0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%); // = Material Design Elevation Lvl 1
$box-shadow-input-field: 0 1px 2px #e5e9eb;

$body-padding: 15.5px;

$wizard-actions-bottom-safe-space: 114px;
$wizard-actions-bottom-safe-space-md: 150px;
$wizard-actions-bottom-safe-space-xl: 84px;

$floater-outer-spacing: 15px;

$bottom-menu-height: 70px;
$small-bottom-menu-height: 110px;
$dialog-margins: 4rem;
$small-dialog-margins: 2rem;
$login-box-width: 345px;
$base-padding: 16px;
$badge-spacing: 5px;
$input-padding-x: 15px;
$nav-pills-spacing-y: 8px;
$data-table-cell-padding: $base-padding;
$data-table-border-spacing-y: 8px;
$context-menu-spacing: 15px;
$context-menu-item-spacing-y: 5px;
$context-menu-body-spacing: #{$context-menu-spacing -
  $context-menu-item-spacing-y} 0;
$list-key-val-item-y: $base-padding;

$hover-filter: brightness(
  110%
); // e.g. for hover effect on background of Button or Badge component

// Form Elements

$placeholder-color: $grey;
$form-field-background-color: $theme-color-card-background;
$form-field-disabled-color: $platinum;
$form-field-border-color: $grey;
$form-field-edited-background-color: #eaf3fe;
$form-field-error-color: $red;
$form-field-error-background-color: #fdeeee; // TODO: 10 % of $negative
$form-field-border-radius: 6px;
$form-field-line-height: $font-size-normal * 1.3333;
$form-field-border-width: 1px;
$form-field-inner-spacing-y: $form-field-line-height * 0.15;
$form-field-inner-spacing-x: 15px;
$form-field-total-height: $form-field-line-height +
  (($form-field-inner-spacing-y + $form-field-border-width) * 2);
$form-field-label-color: $grey;
$form-field-asterisk-color: $theme-color-primary-accent;
$form-field-dropdown-arrow-size: 12px;

// Transitions

$transition-duration-100: 0.2s; // e.g. for button background-color + color
$transition-duration-200: 0.5s; // e.g open/close sliding containers

// Sidesheet

$sidesheet-width: 650px;

//Ticket Status Background colors

$ticket-open-background-color: $color-normal-blue;
$ticket-in-progress-background-color: #F7C976;
$ticket-waiting-for-others-background-color: #f7c976;
$ticket-closed-background-color: #5fbf89;
$ticket-cancelled-background-color: #C5CCD6;

//Resident User Blocked Background Color

$resident-user-blocked-background-color: #ed5d57;

// fade

%horizontal-tabs-fade {
  content: '';
  position: absolute;
  z-index: 2;
  pointer-events: none;
  width: 60px;
  height: 30px;
}
