@use 'helpers/variables';
@use 'helpers/mixins' as m;
@use 'helpers/placeholders';

.dropdown-toggle {
  border-radius: variables.$border-radius-big;
  border-color: variables.$theme-color-neutral;
  box-shadow: variables.$box-shadow-input-field;

  &--frameless {
    @include m.dropdown-toggle-frameless;
  }

  &:not(#{&}--frameless) {
    &:not(:disabled) {
      &:hover,
      &:focus {
        @extend %form-field-active-state;
      }
    }

    .dropdown.show > & {
      @extend %form-field-active-state;
      box-shadow: variables.$box-shadow-default;
    }
  }
}

.dropdown-menu {
  border-radius: variables.$border-radius-big;
  border-color: variables.$border-color-input-field-active;
  box-shadow: variables.$box-shadow-default;
  overflow: hidden;

  &--fully-rounded {
    // = independent dropdown which is not visually connected to the dropdown-trigger
    border-radius: variables.$border-radius-big;

    // 'fully rounded' suggests that the dropdown menu is not connected to a focusable input field
    // therefore the non-focus border color will be used:
    border-color: variables.$theme-color-neutral;
  }

  // &:not(#{&}--fully-rounded) {

  // }

  &--with-top-arrow {
    // let the arrow break out of the parent dropdown menu:
    // (could lead to conflicts if the respective dropdown menu really needs the default overflow hidden)
    overflow: visible;

    &:before,
    &:after {
      content: ' ';
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      right: calc(50% - 8px);
      border-width: 7px 8px;
      border-style: solid;
      border-color: transparent;
      z-index: 1001;
    }

    &:before {
      top: -15px;
      border-bottom-color: variables.$theme-color-neutral;
    }

    &:after {
      top: -14px;
      border-bottom-color: variables.$white;
    }
  }
}
