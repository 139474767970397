
.loading-area {
  width: 100%;
  font-size: 28px;
  text-align: center;
}

.root-loading-area {
  height: 100%;
  position: fixed;
  z-index: 1000;
  background: white;

  app-loading-spinner {
    margin: auto;
  }
}
