@use 'helpers/variables';

@mixin font-face($family-name, $file-name, $weight, $style) {
  $font-path: '/assets/fonts/#{$file-name}';
  @font-face {
    font-family: $family-name;
    font-style: $style;
    font-weight: $weight;
    src: url($font-path + '.woff2') format('woff2'),
      url($font-path + '.woff') format('woff');
  }
}

@include font-face(
  variables.$font-family-name-default,
  'source-sans-pro-extra-light',
  200,
  normal
);
@include font-face(
  variables.$font-family-name-default,
  'source-sans-pro-extra-light-italic',
  200,
  italic
);
@include font-face(
  variables.$font-family-name-default,
  'source-sans-pro-light',
  300,
  normal
);
@include font-face(
  variables.$font-family-name-default,
  'source-sans-pro-light-italic',
  300,
  italic
);
@include font-face(
  variables.$font-family-name-default,
  'source-sans-pro-regular',
  400,
  normal
);
@include font-face(
  variables.$font-family-name-default,
  'source-sans-pro-italic',
  400,
  italic
);
@include font-face(
  variables.$font-family-name-default,
  'source-sans-pro-semi-bold',
  600,
  normal
);
@include font-face(
  variables.$font-family-name-default,
  'source-sans-pro-semi-bold-italic',
  600,
  italic
);
@include font-face(
  variables.$font-family-name-default,
  'source-sans-pro-bold',
  700,
  normal
);
@include font-face(
  variables.$font-family-name-default,
  'source-sans-pro-bold-italic',
  700,
  italic
);
@include font-face(
  variables.$font-family-name-default,
  'source-sans-pro-black',
  900,
  normal
);
@include font-face(
  variables.$font-family-name-default,
  'source-sans-pro-black-italic',
  900,
  italic
);
