@use 'helpers/variables';
@use 'helpers/placeholders';

.form-control {
  height: auto;
  border-radius: variables.$border-radius-big;
  box-shadow: variables.$box-shadow-input-field;

  &,
  &:focus {
    border-color: variables.$theme-color-neutral;
  }

  &:not(:disabled) {
    &:hover,
    &:focus,
    .dropdown.show > &,
    .dropdown.show > fieldset > & {
      @extend %form-field-active-state;
    }
  }

  &:read-only:not(div, select) {
    background-color: #e9ecef;
  }
}

label {
  margin-bottom: 0.5rem;
}
