@use 'helpers/variables';
@use 'helpers/mixins' as m;
@use 'helpers/placeholders';

.ios-padding-top {
  padding-top: env(safe-area-inset-top);
}

.ios-padding-bottom {
  padding-bottom: env(safe-area-inset-bottom);
}

.ios-padding-left {
  padding-left: env(safe-area-inset-left);
}

.ios-padding-right {
  padding-right: env(safe-area-inset-right);
}

.outline-header {
  background-color: variables.$theme-color-card-background;
  box-shadow: 0 1px 0 0 variables.$theme-color-neutral;
}

.outline-content {
  border: 1px solid variables.$theme-color-neutral;
  border-radius: 2px;
  background-color: variables.$theme-color-card-background;
  box-shadow: 0 2px 8px 0 rgba(159, 169, 186, 0.1),
    0 1px 2px 0 rgba(159, 169, 186, 0.1);
}

.cdk-overlay-container lightbox {
  // fix for iphone, move lightbox close button and image count under notch/statusbar
  padding-top: env(safe-area-inset-top);
  // effect only visible on iphone because of padding-top in safe-area. match lightbox bg color
  background-color: #000000;
  z-index: 100;
}

.app-click-area {
  cursor: pointer;
}

.btn-link {
  &,
  &:hover {
    color: variables.$theme-color-primary-accent;
  }
}

.nav-pills {
  .nav-link {
    margin-right: 20px;
    margin-bottom: variables.$nav-pills-spacing-y;
    padding: 0 0 15px;
    font-weight: 600;
    font-size: 17px;
    color: variables.$theme-color-secondary-text;

    &:hover {
      color: variables.$theme-color-secondary-accent-dark;
    }

    &.disabled {
      color: variables.$theme-color-neutral;
      cursor: not-allowed;
    }
  }

  .nav-link.active,
  .show > .nav-link {
    background-color: transparent;
    color: variables.$theme-color-primary-accent;
  }

  .nav-link.active {
    border-bottom: 3px solid variables.$theme-color-primary-accent;
    border-radius: unset;
    z-index: 1;
  }
}

.default-svg-color {
  @include m.colorized-svg-icon(variables.$theme-color-secondary-text);
}

.main-svg-color {
  @include m.colorized-svg-icon(variables.$theme-color-secondary-accent);
}

.secondary-svg-color {
  @include m.colorized-svg-icon(variables.$theme-color-primary-accent);
}

.primary-text-svg-color {
  @include m.colorized-svg-icon(variables.$theme-color-primary-text);
}

.white-svg-color {
  @include m.colorized-svg-icon(variables.$theme-color-button-text);
}

.button-text-svg-color {
  @include m.colorized-svg-icon(variables.$theme-color-button-text);
}

.negative-svg-color {
  @include m.colorized-svg-icon(variables.$theme-color-state-900);
}

.green-svg-color {
  @include m.colorized-svg-icon(variables.$theme-color-state-100);
}

.green-white-svg-color {
  @include m.colorized-svg-icon(variables.$theme-color-state-100);
  @include m.colorized-path-fill-circle-stroke-svg-icon(
    variables.$theme-color-state-100
  );
}

.negative-white-svg-color {
  @include m.colorized-svg-icon(variables.$theme-color-state-900);
  @include m.colorized-path-fill-circle-stroke-svg-icon(
    variables.$theme-color-state-900
  );
  @include m.colorized-path-stroke-svg-icon();
}

.orange-svg-color {
  @include m.colorized-svg-icon(variables.$theme-color-state-700);
}

.yellow-svg-color {
  @include m.colorized-svg-icon(variables.$theme-color-state-500);
}

.bg-primary {
  background-color: variables.$theme-color-secondary-accent !important;
}

input[type='time']::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  opacity: 1;
  background-image: none;
  cursor: pointer;
  z-index: 3;
}

.time-form__field {
  input {
    height: 100%;
    padding-left: 30px;
  }

  img,
  svg-icon {
    z-index: 2;
    position: absolute;
    top: 10px;
    left: 8px;
  }

  app-select {
    ::ng-deep select {
      padding-left: 30px;
    }
  }
}

ngb-accordion {
  .btn {
    background-color: variables.$theme-color-card-background;
  }
}

.card {
  background-color: variables.$theme-color-card-background;
  column-break-inside: avoid;

  &-header {
    background-color: transparent !important;
  }

  &-footer {
    background-color: transparent !important;
    border-radius: 0;
  }

  .card-body {
    border-radius: 0;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
  background-color: rgba(0, 0, 0, 0.5);
}

%divider-line {
  border-top: 1px solid variables.$color-divider-line;
}

%divider-spacing {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

hr {
  @extend %divider-spacing;
  @extend %divider-line;
  opacity: initial;
}

.divider-with-label {
  @extend %divider-spacing;
  text-align: center;
  overflow: hidden;
  color: variables.$theme-color-secondary-text;

  span {
    position: relative;
    font-size: variables.$font-size-small;
    color: variables.$theme-color-primary-text;
    text-transform: uppercase;

    &::before,
    &::after {
      @extend %divider-line;
      content: '';
      position: absolute;
      top: 50%;
      width: 50vw;
    }

    &::before {
      right: 100%;
      margin-right: 10px;
    }

    &::after {
      left: 100%;
      margin-left: 10px;
    }
  }
}

svg-icon {
  display: inline-flex;

  svg:not(:root) {
    overflow: unset;
  }
}

.container {
  &-primary {
    background-color: variables.$theme-color-app-background;
  }

  &-light {
    background-color: variables.$theme-color-card-background;
  }

  &--with-spacing {
    margin-top: #{variables.$base-size * 6};
    margin-bottom: #{variables.$base-size * 5};
    position: relative;
  }

  @media (min-width: variables.$screen-sm-min) {
    max-width: 100%;
  }

  @media (min-width: variables.$screen-lg-min) {
    max-width: 960px;

    &-content {
      max-width: 674px !important;
    }
  }

  @media (min-width: variables.$screen-xl-min) {
    max-width: 1140px;
  }
}

@keyframes skeleton-shine {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.icon {
  line-height: 0;
}

.skeleton {
  @extend %skeleton-animated-background;
}
