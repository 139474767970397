@use 'helpers/variables';

html {
  position: relative;
  min-height: 100%;
}

body,
html {
  color: variables.$theme-color-primary-text;
  font-size: variables.$font-size-normal;
  font-style: normal;
  font-stretch: normal;
  font-family: variables.$font-family-default !important;
  letter-spacing: normal;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  scroll-padding-top: 90px;
}

button,
input,
optgroup,
select,
textarea {
  font-family: variables.$font-family-default !important;
}

* {
  word-wrap: break-word;
}

a {
  color: variables.$theme-color-primary-accent;
  text-decoration: none;

  &:hover {
    color: variables.$theme-color-primary-accent-dark;
    cursor: pointer;
    text-decoration: underline;
  }
}
