@use 'sass:meta';

@layer vendors, layout, components;

@layer vendors {
  @include meta.load-css('vendors');
}

@layer layout {
  @include meta.load-css('layout');
}

@layer components {
  @include meta.load-css('components');
}
