$icons-font: "icons";

@font-face {
    font-family: $icons-font;
    src: url("/assets/fonts/icons.woff?3ada372a7b68ed770e565c239a1216ef") format("woff"),
url("/assets/fonts/icons.woff2?3ada372a7b68ed770e565c239a1216ef") format("woff2");
}

.icon:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$icons-map: (
    "zoom": "\f101",
    "zaps": "\f102",
    "zap": "\f103",
    "xing": "\f104",
    "window": "\f105",
    "width": "\f106",
    "wbs": "\f107",
    "water": "\f108",
    "washing-room": "\f109",
    "washing-machine": "\f10a",
    "warning": "\f10b",
    "uvi_heating": "\f10c",
    "utility-room": "\f10d",
    "user": "\f10e",
    "urinal": "\f10f",
    "upload": "\f110",
    "tv": "\f111",
    "train": "\f112",
    "toilet": "\f113",
    "ticket": "\f114",
    "text-editor": "\f115",
    "terrace": "\f116",
    "sun": "\f117",
    "store": "\f118",
    "store-room": "\f119",
    "star-half": "\f11a",
    "star-filled": "\f11b",
    "star-empty": "\f11c",
    "stairwell": "\f11d",
    "stairs": "\f11e",
    "spinner-filled": "\f11f",
    "spinner-dots": "\f120",
    "sorting": "\f121",
    "shower": "\f122",
    "share": "\f123",
    "settings": "\f124",
    "senior": "\f125",
    "self-disclosure": "\f126",
    "search": "\f127",
    "search-profile": "\f128",
    "school": "\f129",
    "save": "\f12a",
    "rooms": "\f12b",
    "restoration": "\f12c",
    "restaurants": "\f12d",
    "reset": "\f12e",
    "reporting": "\f12f",
    "remove": "\f130",
    "refresh": "\f131",
    "redirect": "\f132",
    "question": "\f133",
    "publish": "\f134",
    "pool": "\f135",
    "plus": "\f136",
    "plug": "\f137",
    "planning": "\f138",
    "plane": "\f139",
    "pipe": "\f13a",
    "piggy-bank": "\f13b",
    "pie-chart": "\f13c",
    "phone": "\f13d",
    "pet": "\f13e",
    "pdf": "\f13f",
    "party-cannon": "\f140",
    "parking": "\f141",
    "paper-plane": "\f142",
    "paper-clip": "\f143",
    "paint-roller": "\f144",
    "paint-brush": "\f145",
    "office": "\f146",
    "offer": "\f147",
    "object": "\f148",
    "notes": "\f149",
    "news": "\f14a",
    "moving-box": "\f14b",
    "more-money": "\f14c",
    "monument": "\f14d",
    "money-pig": "\f14e",
    "money-bag": "\f14f",
    "mobile": "\f150",
    "minus": "\f151",
    "messenger": "\f152",
    "masonry": "\f153",
    "marketing": "\f154",
    "mail": "\f155",
    "logout": "\f156",
    "loggia": "\f157",
    "lock-open": "\f158",
    "lock-closed": "\f159",
    "location": "\f15a",
    "location-outlined": "\f15b",
    "list": "\f15c",
    "linkedin": "\f15d",
    "link": "\f15e",
    "lines-chart": "\f15f",
    "lightning": "\f160",
    "lightning-outlined": "\f161",
    "light-bulb": "\f162",
    "lift": "\f163",
    "level": "\f164",
    "law": "\f165",
    "laundry": "\f166",
    "laundry-room": "\f167",
    "kitchen": "\f168",
    "keyboard": "\f169",
    "key": "\f16a",
    "internet-cable": "\f16b",
    "intercom": "\f16c",
    "info": "\f16d",
    "house-type": "\f16e",
    "house-door": "\f16f",
    "hospital": "\f170",
    "history": "\f171",
    "highway": "\f172",
    "high-voltage": "\f173",
    "height": "\f174",
    "heating": "\f175",
    "heating-costs": "\f176",
    "health-care": "\f177",
    "handicapped": "\f178",
    "hammer": "\f179",
    "half-rooms": "\f17a",
    "globe": "\f17b",
    "gas-container": "\f17c",
    "garden": "\f17d",
    "garage": "\f17e",
    "gallery": "\f17f",
    "furniture": "\f180",
    "food": "\f181",
    "folder": "\f182",
    "folder-outlined": "\f183",
    "floor": "\f184",
    "flashes": "\f185",
    "fireplace": "\f186",
    "fire": "\f187",
    "filter": "\f188",
    "feedback": "\f189",
    "eye-open": "\f18a",
    "eye-closed": "\f18b",
    "extinguisher": "\f18c",
    "export": "\f18d",
    "expand": "\f18e",
    "energy-certificate": "\f18f",
    "elevator": "\f190",
    "editor": "\f191",
    "edit": "\f192",
    "download": "\f193",
    "door": "\f194",
    "document": "\f195",
    "discover": "\f196",
    "detached-link-filled": "\f197",
    "delete": "\f198",
    "data": "\f199",
    "dashboard": "\f19a",
    "crown": "\f19b",
    "crosshair": "\f19c",
    "crane": "\f19d",
    "copy": "\f19e",
    "cookie": "\f19f",
    "contracts": "\f1a0",
    "context": "\f1a1",
    "context-menu-outlined": "\f1a2",
    "contacts": "\f1a3",
    "computer": "\f1a4",
    "compass": "\f1a5",
    "commentary": "\f1a6",
    "coins": "\f1a7",
    "coin": "\f1a8",
    "close": "\f1a9",
    "close-outlined": "\f1aa",
    "clock": "\f1ab",
    "clipboard": "\f1ac",
    "chevron-up": "\f1ad",
    "chevron-right": "\f1ae",
    "chevron-left": "\f1af",
    "chevron-down": "\f1b0",
    "check": "\f1b1",
    "check-framed": "\f1b2",
    "chat-template": "\f1b3",
    "chart-pie": "\f1b4",
    "chart-line": "\f1b5",
    "chart-bar": "\f1b6",
    "celebrations": "\f1b7",
    "calendar": "\f1b8",
    "bus": "\f1b9",
    "burger-menu": "\f1ba",
    "burger-menu-reduce": "\f1bb",
    "burger-menu-expand": "\f1bc",
    "bulb": "\f1bd",
    "building": "\f1be",
    "bubbletub": "\f1bf",
    "board": "\f1c0",
    "bling": "\f1c1",
    "blinders": "\f1c2",
    "bike": "\f1c3",
    "bidet": "\f1c4",
    "bell": "\f1c5",
    "bed": "\f1c6",
    "bathtub": "\f1c7",
    "basement": "\f1c8",
    "barrier-free": "\f1c9",
    "bar-chart": "\f1ca",
    "balcony": "\f1cb",
    "attic": "\f1cc",
    "attention": "\f1cd",
    "assistant": "\f1ce",
    "arrow-up": "\f1cf",
    "arrow-right": "\f1d0",
    "arrow-left": "\f1d1",
    "arrow-dropdown": "\f1d2",
    "arrow-down": "\f1d3",
    "armchair": "\f1d4",
    "area-size": "\f1d5",
    "archive": "\f1d6",
    "air-condition": "\f1d7",
    "add": "\f1d8",
);

.icon.icon--zoom:before {
    content: map-get($icons-map, "zoom");
}
.icon.icon--zaps:before {
    content: map-get($icons-map, "zaps");
}
.icon.icon--zap:before {
    content: map-get($icons-map, "zap");
}
.icon.icon--xing:before {
    content: map-get($icons-map, "xing");
}
.icon.icon--window:before {
    content: map-get($icons-map, "window");
}
.icon.icon--width:before {
    content: map-get($icons-map, "width");
}
.icon.icon--wbs:before {
    content: map-get($icons-map, "wbs");
}
.icon.icon--water:before {
    content: map-get($icons-map, "water");
}
.icon.icon--washing-room:before {
    content: map-get($icons-map, "washing-room");
}
.icon.icon--washing-machine:before {
    content: map-get($icons-map, "washing-machine");
}
.icon.icon--warning:before {
    content: map-get($icons-map, "warning");
}
.icon.icon--uvi_heating:before {
    content: map-get($icons-map, "uvi_heating");
}
.icon.icon--utility-room:before {
    content: map-get($icons-map, "utility-room");
}
.icon.icon--user:before {
    content: map-get($icons-map, "user");
}
.icon.icon--urinal:before {
    content: map-get($icons-map, "urinal");
}
.icon.icon--upload:before {
    content: map-get($icons-map, "upload");
}
.icon.icon--tv:before {
    content: map-get($icons-map, "tv");
}
.icon.icon--train:before {
    content: map-get($icons-map, "train");
}
.icon.icon--toilet:before {
    content: map-get($icons-map, "toilet");
}
.icon.icon--ticket:before {
    content: map-get($icons-map, "ticket");
}
.icon.icon--text-editor:before {
    content: map-get($icons-map, "text-editor");
}
.icon.icon--terrace:before {
    content: map-get($icons-map, "terrace");
}
.icon.icon--sun:before {
    content: map-get($icons-map, "sun");
}
.icon.icon--store:before {
    content: map-get($icons-map, "store");
}
.icon.icon--store-room:before {
    content: map-get($icons-map, "store-room");
}
.icon.icon--star-half:before {
    content: map-get($icons-map, "star-half");
}
.icon.icon--star-filled:before {
    content: map-get($icons-map, "star-filled");
}
.icon.icon--star-empty:before {
    content: map-get($icons-map, "star-empty");
}
.icon.icon--stairwell:before {
    content: map-get($icons-map, "stairwell");
}
.icon.icon--stairs:before {
    content: map-get($icons-map, "stairs");
}
.icon.icon--spinner-filled:before {
    content: map-get($icons-map, "spinner-filled");
}
.icon.icon--spinner-dots:before {
    content: map-get($icons-map, "spinner-dots");
}
.icon.icon--sorting:before {
    content: map-get($icons-map, "sorting");
}
.icon.icon--shower:before {
    content: map-get($icons-map, "shower");
}
.icon.icon--share:before {
    content: map-get($icons-map, "share");
}
.icon.icon--settings:before {
    content: map-get($icons-map, "settings");
}
.icon.icon--senior:before {
    content: map-get($icons-map, "senior");
}
.icon.icon--self-disclosure:before {
    content: map-get($icons-map, "self-disclosure");
}
.icon.icon--search:before {
    content: map-get($icons-map, "search");
}
.icon.icon--search-profile:before {
    content: map-get($icons-map, "search-profile");
}
.icon.icon--school:before {
    content: map-get($icons-map, "school");
}
.icon.icon--save:before {
    content: map-get($icons-map, "save");
}
.icon.icon--rooms:before {
    content: map-get($icons-map, "rooms");
}
.icon.icon--restoration:before {
    content: map-get($icons-map, "restoration");
}
.icon.icon--restaurants:before {
    content: map-get($icons-map, "restaurants");
}
.icon.icon--reset:before {
    content: map-get($icons-map, "reset");
}
.icon.icon--reporting:before {
    content: map-get($icons-map, "reporting");
}
.icon.icon--remove:before {
    content: map-get($icons-map, "remove");
}
.icon.icon--refresh:before {
    content: map-get($icons-map, "refresh");
}
.icon.icon--redirect:before {
    content: map-get($icons-map, "redirect");
}
.icon.icon--question:before {
    content: map-get($icons-map, "question");
}
.icon.icon--publish:before {
    content: map-get($icons-map, "publish");
}
.icon.icon--pool:before {
    content: map-get($icons-map, "pool");
}
.icon.icon--plus:before {
    content: map-get($icons-map, "plus");
}
.icon.icon--plug:before {
    content: map-get($icons-map, "plug");
}
.icon.icon--planning:before {
    content: map-get($icons-map, "planning");
}
.icon.icon--plane:before {
    content: map-get($icons-map, "plane");
}
.icon.icon--pipe:before {
    content: map-get($icons-map, "pipe");
}
.icon.icon--piggy-bank:before {
    content: map-get($icons-map, "piggy-bank");
}
.icon.icon--pie-chart:before {
    content: map-get($icons-map, "pie-chart");
}
.icon.icon--phone:before {
    content: map-get($icons-map, "phone");
}
.icon.icon--pet:before {
    content: map-get($icons-map, "pet");
}
.icon.icon--pdf:before {
    content: map-get($icons-map, "pdf");
}
.icon.icon--party-cannon:before {
    content: map-get($icons-map, "party-cannon");
}
.icon.icon--parking:before {
    content: map-get($icons-map, "parking");
}
.icon.icon--paper-plane:before {
    content: map-get($icons-map, "paper-plane");
}
.icon.icon--paper-clip:before {
    content: map-get($icons-map, "paper-clip");
}
.icon.icon--paint-roller:before {
    content: map-get($icons-map, "paint-roller");
}
.icon.icon--paint-brush:before {
    content: map-get($icons-map, "paint-brush");
}
.icon.icon--office:before {
    content: map-get($icons-map, "office");
}
.icon.icon--offer:before {
    content: map-get($icons-map, "offer");
}
.icon.icon--object:before {
    content: map-get($icons-map, "object");
}
.icon.icon--notes:before {
    content: map-get($icons-map, "notes");
}
.icon.icon--news:before {
    content: map-get($icons-map, "news");
}
.icon.icon--moving-box:before {
    content: map-get($icons-map, "moving-box");
}
.icon.icon--more-money:before {
    content: map-get($icons-map, "more-money");
}
.icon.icon--monument:before {
    content: map-get($icons-map, "monument");
}
.icon.icon--money-pig:before {
    content: map-get($icons-map, "money-pig");
}
.icon.icon--money-bag:before {
    content: map-get($icons-map, "money-bag");
}
.icon.icon--mobile:before {
    content: map-get($icons-map, "mobile");
}
.icon.icon--minus:before {
    content: map-get($icons-map, "minus");
}
.icon.icon--messenger:before {
    content: map-get($icons-map, "messenger");
}
.icon.icon--masonry:before {
    content: map-get($icons-map, "masonry");
}
.icon.icon--marketing:before {
    content: map-get($icons-map, "marketing");
}
.icon.icon--mail:before {
    content: map-get($icons-map, "mail");
}
.icon.icon--logout:before {
    content: map-get($icons-map, "logout");
}
.icon.icon--loggia:before {
    content: map-get($icons-map, "loggia");
}
.icon.icon--lock-open:before {
    content: map-get($icons-map, "lock-open");
}
.icon.icon--lock-closed:before {
    content: map-get($icons-map, "lock-closed");
}
.icon.icon--location:before {
    content: map-get($icons-map, "location");
}
.icon.icon--location-outlined:before {
    content: map-get($icons-map, "location-outlined");
}
.icon.icon--list:before {
    content: map-get($icons-map, "list");
}
.icon.icon--linkedin:before {
    content: map-get($icons-map, "linkedin");
}
.icon.icon--link:before {
    content: map-get($icons-map, "link");
}
.icon.icon--lines-chart:before {
    content: map-get($icons-map, "lines-chart");
}
.icon.icon--lightning:before {
    content: map-get($icons-map, "lightning");
}
.icon.icon--lightning-outlined:before {
    content: map-get($icons-map, "lightning-outlined");
}
.icon.icon--light-bulb:before {
    content: map-get($icons-map, "light-bulb");
}
.icon.icon--lift:before {
    content: map-get($icons-map, "lift");
}
.icon.icon--level:before {
    content: map-get($icons-map, "level");
}
.icon.icon--law:before {
    content: map-get($icons-map, "law");
}
.icon.icon--laundry:before {
    content: map-get($icons-map, "laundry");
}
.icon.icon--laundry-room:before {
    content: map-get($icons-map, "laundry-room");
}
.icon.icon--kitchen:before {
    content: map-get($icons-map, "kitchen");
}
.icon.icon--keyboard:before {
    content: map-get($icons-map, "keyboard");
}
.icon.icon--key:before {
    content: map-get($icons-map, "key");
}
.icon.icon--internet-cable:before {
    content: map-get($icons-map, "internet-cable");
}
.icon.icon--intercom:before {
    content: map-get($icons-map, "intercom");
}
.icon.icon--info:before {
    content: map-get($icons-map, "info");
}
.icon.icon--house-type:before {
    content: map-get($icons-map, "house-type");
}
.icon.icon--house-door:before {
    content: map-get($icons-map, "house-door");
}
.icon.icon--hospital:before {
    content: map-get($icons-map, "hospital");
}
.icon.icon--history:before {
    content: map-get($icons-map, "history");
}
.icon.icon--highway:before {
    content: map-get($icons-map, "highway");
}
.icon.icon--high-voltage:before {
    content: map-get($icons-map, "high-voltage");
}
.icon.icon--height:before {
    content: map-get($icons-map, "height");
}
.icon.icon--heating:before {
    content: map-get($icons-map, "heating");
}
.icon.icon--heating-costs:before {
    content: map-get($icons-map, "heating-costs");
}
.icon.icon--health-care:before {
    content: map-get($icons-map, "health-care");
}
.icon.icon--handicapped:before {
    content: map-get($icons-map, "handicapped");
}
.icon.icon--hammer:before {
    content: map-get($icons-map, "hammer");
}
.icon.icon--half-rooms:before {
    content: map-get($icons-map, "half-rooms");
}
.icon.icon--globe:before {
    content: map-get($icons-map, "globe");
}
.icon.icon--gas-container:before {
    content: map-get($icons-map, "gas-container");
}
.icon.icon--garden:before {
    content: map-get($icons-map, "garden");
}
.icon.icon--garage:before {
    content: map-get($icons-map, "garage");
}
.icon.icon--gallery:before {
    content: map-get($icons-map, "gallery");
}
.icon.icon--furniture:before {
    content: map-get($icons-map, "furniture");
}
.icon.icon--food:before {
    content: map-get($icons-map, "food");
}
.icon.icon--folder:before {
    content: map-get($icons-map, "folder");
}
.icon.icon--folder-outlined:before {
    content: map-get($icons-map, "folder-outlined");
}
.icon.icon--floor:before {
    content: map-get($icons-map, "floor");
}
.icon.icon--flashes:before {
    content: map-get($icons-map, "flashes");
}
.icon.icon--fireplace:before {
    content: map-get($icons-map, "fireplace");
}
.icon.icon--fire:before {
    content: map-get($icons-map, "fire");
}
.icon.icon--filter:before {
    content: map-get($icons-map, "filter");
}
.icon.icon--feedback:before {
    content: map-get($icons-map, "feedback");
}
.icon.icon--eye-open:before {
    content: map-get($icons-map, "eye-open");
}
.icon.icon--eye-closed:before {
    content: map-get($icons-map, "eye-closed");
}
.icon.icon--extinguisher:before {
    content: map-get($icons-map, "extinguisher");
}
.icon.icon--export:before {
    content: map-get($icons-map, "export");
}
.icon.icon--expand:before {
    content: map-get($icons-map, "expand");
}
.icon.icon--energy-certificate:before {
    content: map-get($icons-map, "energy-certificate");
}
.icon.icon--elevator:before {
    content: map-get($icons-map, "elevator");
}
.icon.icon--editor:before {
    content: map-get($icons-map, "editor");
}
.icon.icon--edit:before {
    content: map-get($icons-map, "edit");
}
.icon.icon--download:before {
    content: map-get($icons-map, "download");
}
.icon.icon--door:before {
    content: map-get($icons-map, "door");
}
.icon.icon--document:before {
    content: map-get($icons-map, "document");
}
.icon.icon--discover:before {
    content: map-get($icons-map, "discover");
}
.icon.icon--detached-link-filled:before {
    content: map-get($icons-map, "detached-link-filled");
}
.icon.icon--delete:before {
    content: map-get($icons-map, "delete");
}
.icon.icon--data:before {
    content: map-get($icons-map, "data");
}
.icon.icon--dashboard:before {
    content: map-get($icons-map, "dashboard");
}
.icon.icon--crown:before {
    content: map-get($icons-map, "crown");
}
.icon.icon--crosshair:before {
    content: map-get($icons-map, "crosshair");
}
.icon.icon--crane:before {
    content: map-get($icons-map, "crane");
}
.icon.icon--copy:before {
    content: map-get($icons-map, "copy");
}
.icon.icon--cookie:before {
    content: map-get($icons-map, "cookie");
}
.icon.icon--contracts:before {
    content: map-get($icons-map, "contracts");
}
.icon.icon--context:before {
    content: map-get($icons-map, "context");
}
.icon.icon--context-menu-outlined:before {
    content: map-get($icons-map, "context-menu-outlined");
}
.icon.icon--contacts:before {
    content: map-get($icons-map, "contacts");
}
.icon.icon--computer:before {
    content: map-get($icons-map, "computer");
}
.icon.icon--compass:before {
    content: map-get($icons-map, "compass");
}
.icon.icon--commentary:before {
    content: map-get($icons-map, "commentary");
}
.icon.icon--coins:before {
    content: map-get($icons-map, "coins");
}
.icon.icon--coin:before {
    content: map-get($icons-map, "coin");
}
.icon.icon--close:before {
    content: map-get($icons-map, "close");
}
.icon.icon--close-outlined:before {
    content: map-get($icons-map, "close-outlined");
}
.icon.icon--clock:before {
    content: map-get($icons-map, "clock");
}
.icon.icon--clipboard:before {
    content: map-get($icons-map, "clipboard");
}
.icon.icon--chevron-up:before {
    content: map-get($icons-map, "chevron-up");
}
.icon.icon--chevron-right:before {
    content: map-get($icons-map, "chevron-right");
}
.icon.icon--chevron-left:before {
    content: map-get($icons-map, "chevron-left");
}
.icon.icon--chevron-down:before {
    content: map-get($icons-map, "chevron-down");
}
.icon.icon--check:before {
    content: map-get($icons-map, "check");
}
.icon.icon--check-framed:before {
    content: map-get($icons-map, "check-framed");
}
.icon.icon--chat-template:before {
    content: map-get($icons-map, "chat-template");
}
.icon.icon--chart-pie:before {
    content: map-get($icons-map, "chart-pie");
}
.icon.icon--chart-line:before {
    content: map-get($icons-map, "chart-line");
}
.icon.icon--chart-bar:before {
    content: map-get($icons-map, "chart-bar");
}
.icon.icon--celebrations:before {
    content: map-get($icons-map, "celebrations");
}
.icon.icon--calendar:before {
    content: map-get($icons-map, "calendar");
}
.icon.icon--bus:before {
    content: map-get($icons-map, "bus");
}
.icon.icon--burger-menu:before {
    content: map-get($icons-map, "burger-menu");
}
.icon.icon--burger-menu-reduce:before {
    content: map-get($icons-map, "burger-menu-reduce");
}
.icon.icon--burger-menu-expand:before {
    content: map-get($icons-map, "burger-menu-expand");
}
.icon.icon--bulb:before {
    content: map-get($icons-map, "bulb");
}
.icon.icon--building:before {
    content: map-get($icons-map, "building");
}
.icon.icon--bubbletub:before {
    content: map-get($icons-map, "bubbletub");
}
.icon.icon--board:before {
    content: map-get($icons-map, "board");
}
.icon.icon--bling:before {
    content: map-get($icons-map, "bling");
}
.icon.icon--blinders:before {
    content: map-get($icons-map, "blinders");
}
.icon.icon--bike:before {
    content: map-get($icons-map, "bike");
}
.icon.icon--bidet:before {
    content: map-get($icons-map, "bidet");
}
.icon.icon--bell:before {
    content: map-get($icons-map, "bell");
}
.icon.icon--bed:before {
    content: map-get($icons-map, "bed");
}
.icon.icon--bathtub:before {
    content: map-get($icons-map, "bathtub");
}
.icon.icon--basement:before {
    content: map-get($icons-map, "basement");
}
.icon.icon--barrier-free:before {
    content: map-get($icons-map, "barrier-free");
}
.icon.icon--bar-chart:before {
    content: map-get($icons-map, "bar-chart");
}
.icon.icon--balcony:before {
    content: map-get($icons-map, "balcony");
}
.icon.icon--attic:before {
    content: map-get($icons-map, "attic");
}
.icon.icon--attention:before {
    content: map-get($icons-map, "attention");
}
.icon.icon--assistant:before {
    content: map-get($icons-map, "assistant");
}
.icon.icon--arrow-up:before {
    content: map-get($icons-map, "arrow-up");
}
.icon.icon--arrow-right:before {
    content: map-get($icons-map, "arrow-right");
}
.icon.icon--arrow-left:before {
    content: map-get($icons-map, "arrow-left");
}
.icon.icon--arrow-dropdown:before {
    content: map-get($icons-map, "arrow-dropdown");
}
.icon.icon--arrow-down:before {
    content: map-get($icons-map, "arrow-down");
}
.icon.icon--armchair:before {
    content: map-get($icons-map, "armchair");
}
.icon.icon--area-size:before {
    content: map-get($icons-map, "area-size");
}
.icon.icon--archive:before {
    content: map-get($icons-map, "archive");
}
.icon.icon--air-condition:before {
    content: map-get($icons-map, "air-condition");
}
.icon.icon--add:before {
    content: map-get($icons-map, "add");
}
