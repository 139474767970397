@use 'sass:color';
@use 'helpers/variables';

.blocked-addon {
  height: 100%;
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: linear-gradient(
      to top,
      variables.$theme-color-neutral,
      transparent
    );
  }

  &.white {
    &:before {
      background: linear-gradient(
        to top,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0)
      );
    }
  }
}
