@use 'helpers/mixins' as m;

// Margin & Padding helpers                        |
// - - - - - - - - - - - - - - - - - - - - - - - - |

$spacing-sizes: 0, 4, 5, 6, 7, 8, 9, 10, 12, 15, 16, 20, 24, 25, 30, 32, 40, 48, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 200, 300;

/* Prefix classes for spacings */
$spacing-classes: (
  'p': padding,
  'pt': padding-top,
  'pb': padding-bottom,
  'pr': padding-right,
  'pl': padding-left,
  'm': margin,
  'mt': margin-top,
  'mb': margin-bottom,
  'mr': margin-right,
  'ml': margin-left
);

/* Spacings class loop */
@each $prefix, $meaning in $spacing-classes {
  @each $size in $spacing-sizes{
    @include m.selector-with-viewport-cases(".#{$prefix}#{$size}") {
      #{$meaning}: $size * 1px !important;
    }
  }
}
