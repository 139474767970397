@use 'helpers/variables';

// Font sizes

.title-xxl {
  font-size: 43px;
  font-weight: 700;
  line-height: 52px;
}

.title-xl {
  font-size: 25px;
  line-height: 32px;
  font-weight: 600;
}

.title-m {
  font-size: 17px;
  line-height: 24px;
  font-weight: 600;
}

.title-s {
  font-size: 16px;
  line-height: 24px;
}

.title-xs {
  font-size: 12px;
  line-height: 16px;
}

.default-s {
  font-size: 15px;
  line-height: 20px;
}

.title-grey-xs {
  @extend .title-xs;
  color: variables.$grey;
}

.default-grey-s {
  @extend .default-s;
  color: variables.$grey;
}

.uppercase {
  text-transform: uppercase;
}

.uppercase-m {
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.sub-headline {
  color: variables.$grey;
  text-transform: uppercase;
}

// Font weight

.light {
  font-weight: 300;
}

.regular {
  font-weight: normal;
}

.semibold {
  font-weight: 600;
}

.tiny {
  font-size: 10px;
}
