@use 'helpers/variables';

@media (min-width: variables.$screen-xs-max) {
  %modal {
    max-width: 100%;
  }

  .modal-dialog {
    padding-top: env(safe-area-inset-top);
  }

  .modal-xs {
    @extend %modal;
    width: 366px;
  }

  .modal-s {
    @extend %modal;
    width: 396px;
  }

  .modal-m {
    @extend %modal;
    width: 456px;
  }

  .modal-l {
    @extend %modal;
    width: 618px;
  }

  .modal-xl {
    @extend %modal;
    width: 684px;
  }
  .modal-xxl {
    @extend %modal;
    width: 1140px;
  }
}
