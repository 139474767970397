@use 'helpers/variables';

.btn:focus,
.btn.focus {
  box-shadow: none;
}

.btn-link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  border-color: variables.$theme-color-neutral;
}
